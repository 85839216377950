<template>
  <div class="scroll" :class="{'m': $vuetify.breakpoint.smAndDown}">
    <div class="mouse_scroll" :class="{'on': isAppBarColor}">
      <div class="mouse" :class="{'on': isAppBarColor, 'm': $vuetify.breakpoint.smAndDown}">
        <div class="wheel" :class="{'on': isAppBarColor}"></div>
      </div>
      <div>
        <span class="m_scroll_arrows unu" :class="{'on': isAppBarColor, 'm': $vuetify.breakpoint.smAndDown}"></span>
        <span class="m_scroll_arrows doi" :class="{'on': isAppBarColor, 'm': $vuetify.breakpoint.smAndDown}"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
    }
  },
  name: 'Scroller',
  computed: {
    ...mapGetters(['isAppBarColor'])
  },
}
</script>

<style lang="scss" scoped>
.m.scroll {
  top: calc(100vh - 240px);
}
.scroll {
  position: absolute;
  top: calc(100vh - 260px);
  left: calc((100% - 50%) - 12px);
}

body {
	background: #333;
}


*, *:before, *:after {
  -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}
.mouse_scroll {
	display: block;
	margin: 0 auto;
	width: 24px;
	height: 100px;
	margin-top: 125px;
}

.m.m_scroll_arrows {
  display: none;
}

.m_scroll_arrows.on {
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}

.m_scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
   
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  margin: 0 0 3px 4px;
  
  width: 16px;
  height: 16px;
}


.unu {
  margin-top: 1px;
}

.unu, .doi {
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
    animation: mouse-scroll 1s infinite;
  
}

.unu
{
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  -webkit-animation-direction: alternate;
  
  animation-direction: alternate;
  animation-delay: alternate;
}
.doi
{
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
  
  animation-delay: .2s;
  animation-direction: alternate;
  
  margin-top: -6px;
}

.mouse.on {
  border: 2px solid black;
}

.mouse {
  height: 42px;
  width: 24px;
  border-radius: 14px;
  transform: none;
  border: 2px solid black;
  top: 170px;
}

.m.mouse {
  display: none;
}

.wheel.on {
  background: black;
  border: 2px solid black;
}

.wheel {
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: black;
  position: relative;
  
  height: 4px;
  width: 4px;
  border: 2px solid black;
  -webkit-border-radius: 8px;
          border-radius: 8px;
}

.wheel {
  -webkit-animation: mouse-wheel 0.6s linear infinite;
  -moz-animation: mouse-wheel 0.6s linear infinite;
  animation: mouse-wheel 0.6s linear infinite;
}

@-webkit-keyframes mouse-wheel{
   0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-moz-keyframes mouse-wheel {
  0% { top: 1px; }
  25% { top: 2px; }
  50% { top: 3px;}
  75% { top: 2px;}
  100% { top: 1px;}
}
@-o-keyframes mouse-wheel {

   0% { top: 1px; }
  25% { top: 2px; }
  50% { top: 3px;}
  75% { top: 2px;}
  100% { top: 1px;}
}
@keyframes mouse-wheel {

   0% { top: 1px; }
  25% { top: 2px; }
  50% { top: 3px;}
  75% { top: 2px;}
  100% { top: 1px;}
}

@-webkit-keyframes mouse-scroll {

  0%   { opacity: 0;}
  50%  { opacity: .5;}
  100% { opacity: 1;}
}
@-moz-keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@-o-keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}

</style>